@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-primary: theme("colors.cream");
  --theme-secondary: theme("colors.green");
  --theme-tertiary: theme("colors.black");
}

html {
  @apply overscroll-y-contain;
}

body {
  @apply bg-cream text-black font-sans antialiased flex flex-col min-h-screen;
}

@layer utilities {
  .inline-link {
    @apply underline hover:no-underline mobile-tap-area decoration-lightGrey decoration-1 underline-offset-4 text-nowrap;
    font-feature-settings: "ss03" on;
  }

  .vertical-flow {
    @apply flex flex-col gap-y-16 grow shrink-0;
  }

  .vertical-flow-large {
    @apply flex flex-col gap-y-16 sm:gap-y-24 grow shrink-0;
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .fade-container {
    @apply relative overflow-hidden w-full;
    --fade-size: 100px;

    @media screen(md) {
      --fade-size: 200px;
    }

    mask-image: linear-gradient(
      to right,
      transparent,
      black calc(0% + var(--fade-size)),
      black calc(100% - var(--fade-size)),
      transparent
    );
  }

  .fade-scroll-container {
    @apply relative overflow-hidden w-full;
    --fade-size: 100px;

    @media screen(md) {
      --fade-size: 200px;
    }

    mask-image: linear-gradient(
      to right,
      transparent,
      black calc(0% + var(--fade-size)),
      black calc(100% - var(--fade-size)),
      transparent
    );

    @supports ((animation-timeline: scroll()) and (animation-range: 0% 100%)) {
      animation: mask-fade linear forwards;
      animation-timeline: scroll(x self);
    }
  }

  @keyframes mask-fade {
    0% {
      mask-image: linear-gradient(
        to right,
        black calc(100% - var(--fade-size)),
        transparent
      );
    }
    5%,
    95% {
      mask-image: linear-gradient(
        to right,
        transparent,
        black calc(0% + var(--fade-size)),
        black calc(100% - var(--fade-size)),
        transparent
      );
    }
    100% {
      mask-image: linear-gradient(
        to right,
        transparent,
        black calc(0% + var(--fade-size))
      );
    }
  }

  .sticky-top {
    @apply sticky top-14 sm:top-[90px];

  }

  .wobble {
    animation: wobble 1s ease forwards;
  }

  .wobble-on-hover {
    &:hover {
      @apply wobble;
    }
  }

  @keyframes wobble {
    0%,
    100% {
      transform-origin: 50% 50%;
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-5deg);
    }

    50% {
      transform: rotate(5deg);
    }
  }

  .bg-noise {
    &:before {
      @apply content-[''] -z-10 inset-0 absolute bg-texture bg-repeat bg-[length:250px_250px] mix-blend-multiply saturate-0;
    }
  }

  .bg-noise-light {
    @apply bg-noise;

    &:before {
      @apply opacity-30;
    }
  }

  .circle-mark {
    @apply relative not-italic  -z-10;

    &:before {
      @apply content-[''] absolute-center -z-10 mt-2 -inset-36 md:bg-circle-mark bg-contain bg-no-repeat bg-center;
    }
  }

  .underline-mark {
    @apply relative z-0 no-underline;
    &:before {
      @apply content-[''] absolute -z-10 left-0 right-0 top-full h-2 bg-line-mark bg-contain bg-no-repeat bg-center;
    }
  }

  .opacity-hover {
    @apply transition-opacity duration-200 motion-reduce:transition-none;

    &:hover {
      @apply opacity-70;
    }
  }

  .zoom-hover {
    @apply overflow-clip;

    .zoom-hover-item {
      @apply transition-transform duration-1000;
    }

    &:has(.zoom-hover-link:hover, .zoom-hover-link:focus) .zoom-hover-item {
      @apply scale-105 duration-500 motion-reduce:scale-100;
    }
  }

  .fade-in {
    opacity: 0;
    animation: fade 400ms ease-in forwards;
  }

  .fade-in-late {
    opacity: 0;
    animation: fade 400ms ease-in forwards 50ms;
  }

  .fade-in-later {
    opacity: 0;
    animation: fade 400ms ease-in forwards 100ms;
  }

  .fade-reveal {
    animation: fade ease-in forwards;
    animation-range: 0vh 10vh;
    animation-timeline: view();
  }

  .fade-reveal-none {
    @apply opacity-100;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .zoom-reveal {
    @apply overflow-clip;

    .zoom-reveal-item {
      animation: grow ease-in forwards;
      animation-range: 0vh 10vh;
      animation-timeline: view(y);
    }
  }

  @keyframes shrink {
    from {
      scale: 1.2;
    }
    to {
      scale: 1;
    }
  }

  .transition-show-hide {
    @apply opacity-0 invisible;

    &.is-visible {
      @apply opacity-100 visible;
    }
  }

  .has-shadow {
    @apply relative;

    &:after {
      @apply content-[''] absolute inset-0 shadow-inner;
    }
  }

  .hero-scroll-animation {
    animation-range: 0vh 110vh;
    animation-timeline: scroll(root block);
  }

  @keyframes grow {
    from {
      scale: 1;
    }
    to {
      scale: 1.2;
    }
  }

  .mobile-tap-area {
    @apply relative;

    &:after {
      @apply content-[''] absolute-center min-w-11 min-h-11 w-full h-full md:content-none;
    }
  }

  .scroll-grid {
    @apply w-full max-w-[100vw] snap-x snap-mandatory overflow-x-auto scrollbar-hide;
  }

  .scroll-hold {
    @apply scroll-grid;

    @supports ((animation-timeline: scroll()) and (animation-range: 0% 100%)) {
      @apply relative overflow-x-clip snap-none;
      height: 1590px;

      .scroll-hold-inner {
        @apply absolute top-0 bottom-0 min-w-full motion-reduce:animate-none;
        view-timeline: --scrollHold block;
        animation-timeline: --scrollHold;
        animation-name: holdBody;
        animation-timing-function: linear;
        animation-range-start: entry 100%;
        animation-range-end: exit 100%;
      }
    }
  }

  @keyframes holdBody {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(calc(-100% + 100vw), 50%);
    }

    100% {
      transform: translate(calc(-100% + 100vw), 50%);
    }
  }

  .marquee {
    animation: marquee 180s linear infinite;
  }

  .marquee-reverse {
    animation: marquee 180s linear infinite reverse;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .marquee,
    .marquee-reverse,
    .fade-in,
    .fade-in-late,
    .fade-in-later,
    .fade-reveal,
    .zoom-reveal .zoom-reveal-item,
    .hero-scroll-animation {
      animation: none;
      transition: none;
      opacity: unset;
    }
  }
}

@layer components {
  .bg-grid {
    @apply relative z-0;

    --grid-color: theme("colors.darkCream");
    --grid-stroke: 1px;
    --grid-width: 1lh;

    @media screen(md) {
      --grid-width: 2lh;
    }

    &.bg-grid-dark {
      --grid-color: rgba(255, 255, 255, 0.1);
    }

    &:before {
      @apply content-[''] block absolute inset-0 m-auto -z-10;
      width: calc(round(to-zero, 100%, var(--grid-width)) + 1px);
      height: calc(round(to-zero, 100%, var(--grid-width)) + 1px);
      background: repeating-linear-gradient(
          var(--grid-color) 0px,
          var(--grid-color) var(--grid-stroke),
          transparent var(--grid-stroke),
          transparent 100%
        ),
        repeating-linear-gradient(
          0.25turn,
          var(--grid-color) 0px,
          var(--grid-color) var(--grid-stroke),
          transparent var(--grid-stroke),
          transparent 100%
        );
      background-size: var(--grid-width) var(--grid-width);
    }
  }

  .max-w-rich-text {
    @apply max-w-[92ch] mx-auto;
  }

  .rich-text {
    @apply flex flex-col gap-[1lh] max-w-rich-text;

    h1,
    h2,
    h3,
    h4,
    h5 {
      @apply mt-16 mb-4 text-center first:mt-0 last:mb-0;
      & strong {
        font-weight: inherit;
      }
    }

    ul,
    ol {
      @apply flex flex-col gap-[1lh] text-lg sm:text-xl leading-normal list-outside pl-6;
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }
  }

  .button {
    @apply w-auto sm:text-lg leading-none font-bold inline-flex items-center px-[2em] py-[1em] rounded-full opacity-hover truncate text-ellipsis no-underline;
  }

  .button--dark {
    @apply bg-black text-white;
  }

  .button--light {
    @apply bg-white;
  }

  .button--outline {
    @apply border border-lightGrey;
  }

  .responsive {
    @apply tracking-tight leading-[1.125em] font-light;
    font-size: clamp(3rem, 4.5vw, 9rem);
    @media screen(md) {
      font-size: clamp(5rem, 4.5vw, 9rem);
    }
  }
}
